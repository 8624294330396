import { publicUrlFor } from "../../../globals/constants";
import { Page, Document, pdfjs } from 'react-pdf';
import { useEffect, useState } from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import pdf from '../../../Assets/termsandconditions.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function TermsAndConditions() {
    const [width, setWidth] = useState(window.innerWidth);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
            setScale(newWidth > 1200 ? 1.7 : newWidth*1.3 / 1200);
        };

        handleResize(); // Set initial scale based on the initial window width
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="section-full bg-change-section overlay-wraper p-t80 p-b50">
            <div className="container">
                <div className="section-content">
                    <Document
                        file={pdf}
                        className="d-flex justify-content-center flex-column align-items-center overflow-auto"
                    >
                        <Page pageNumber={1} scale={scale} />
                        <Page pageNumber={2} scale={scale} />
                    </Document>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
